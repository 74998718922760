'use strict'
/*
 * Copyright 2021 ScaleDynamic SAS. All rights reserved.
 * Do not distribute
 * 
 *  BIG web frontend
 * 
 */
let debug = false
if (process.env.FE_DEBUG === "true") debug = true

const ver = '2.0.2'
let version = ver
if (process.env.FE_MODE !== 'prod')
    version = ver + '-' + process.env.FE_MODE

console.log('web version ' + version)

const $JC = jQuery.noConflict();
import 'regenerator-runtime'

import { strict } from "assert"
import Cookies from "js-cookie";
let deviceId = Cookies.get(`big-device-id`)
let numEjobs = Cookies.get(`big-num-jobs`)

if (numEjobs === undefined) numEjobs = 0

let big

if (process.env.BIG_EMULATE === 'true') {
    big = require('./big_emulate.js')
} else {
    big = require('./big_main.js')
}

import * as Sentry from "@sentry/browser";
import { defaultRequestInstrumentationOptions, Integrations } from "@sentry/tracing";

if (process.env.FE_MODE !== 'dev') {
    Sentry.init({
        dsn: "https://0f9462d0c2624f548a0f89d1c97ff3c3@o1112596.ingest.sentry.io/6142341",
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0,
    });
}

import Typewriter from 'typewriter-effect/dist/core'
let getJobTimeout

function startJob(details) {
    console.log('startJob', details)

    $JC('#big_status').html('Calcul en cours');
    $JC('#big_compute_details').html(details);
    $JC('#big_compute_details').show()
    $JC('#big_compute_waiting').hide()
}

function oneJobDone() {
    console.log('oneJobDone')
    numEjobs++
    Cookies.set(`big-num-jobs`, numEjobs, {
        domain: process.env.FE_COOKIE_DOMAIN,
        expires: 365, // in days
        path: '/',
        sameSite: 'None',
        secure: true
    })
}

function waitJob() {
    console.log('waitJob')

    $JC('#big_status').html('En attente de calcul');
    $JC('#big_compute_details').hide()
    $JC('#big_compute_waiting').show()
}

const infoRefreshPeriod = 1
let infoCurrentPeriod = 0
let infoWhichInfo = 0

function showStats() {
    let desc
    let value
    if (infoWhichInfo === 0) {
        desc = "Nombre de calculs r&eacute;alis&eacute;s sur cet appareil"
        value = numEjobs
    }
    if (infoWhichInfo === 1) {
        desc = "Nombre d'appareils participants &agrave; BIG"
        value = bigStatus.total.contributors
    }
    if (infoWhichInfo === 2) {
        desc = "Nombre total de calculs r&eacute;alis&eacute;s sur BIG"
        value = bigStatus.total.ejobs
    }
    infoWhichInfo++
    if (infoWhichInfo === 3) infoWhichInfo = 0
    $JC('#big_stat_description').html(desc)
    $JC('#big_stat_number').html(value)
    $JC('.big_stat').fadeIn(1000)
}

function refreshBigInfos() {
    if (infoCurrentPeriod === infoRefreshPeriod) {
        $JC('#big_stat_description').fadeOut(1000)
        $JC('#big_stat_number').fadeOut(1000, showStats)
        infoCurrentPeriod = 0
    } else
        infoCurrentPeriod++
}

const imgRefreshPeriod = 6
let imgCurrentPeriod = 0
let imgWhichOne = 0
let adsCurrentPeriod = 0

let imageInfos = [
    { author: 'Photo de Kirk', desc: 'Tr&eacute;gastel' },
    { author: 'Photo de Kirk', desc: 'Ch&acirc;teau de La Roche Goyon, Fort La Latte' },
    { author: 'Photo de Kirk', desc: 'Baie de Douarnenez, vue des Sables Blancs' },
    { author: 'Photo de Kirk', desc: 'Radom - Pleumeur Bodou' },
    { author: 'Photo de Kirk', desc: 'Plage de Kervel' },
    { author: 'Photo de Kirk', desc: 'C&ocirc;te de Dinard' },
]

let zone_sdlogo = false
function showImage() {
    $JC("#photo").css("background-image", "url('./img/img_" + imgWhichOne + ".jpeg')")
    $JC('#photo_auteur').html(imageInfos[imgWhichOne].author)
    $JC('#photo_description').html(imageInfos[imgWhichOne].desc)
    imgWhichOne++
    if (imgWhichOne === imageInfos.length) imgWhichOne = 0
    $JC('.zone_sdlogo').fadeOut(1000, () => {
        $JC('.zone_photo').fadeIn(1000)
    })
    zone_sdlogo = false
}
function refreshImage() {

    function toogleAds() {
        if (zone_sdlogo) {
            $JC('.zone_sdlogo').fadeOut(1000, () => {
                $JC('.zone_photo').fadeIn(1000)
            })
            zone_sdlogo = false
        } else {
            $JC('.zone_photo').fadeOut(1000, () => {
                $JC('.zone_sdlogo').fadeIn(1000)
            })
            zone_sdlogo = true
        }
    }

    if (imgCurrentPeriod === imgRefreshPeriod) {
        showImage()
        imgCurrentPeriod = 0
        adsCurrentPeriod = 0
    } else {
        imgCurrentPeriod++
        adsCurrentPeriod++
        if (adsCurrentPeriod == 2) {
            adsCurrentPeriod = 0
            toogleAds()
        }
    }
}

let timeoutRefreshView
function refreshView() {
    refreshBigInfos()
    refreshImage()
    timeoutRefreshView = setTimeout(refreshView, 5000)
}

function initWidget() {
    const typewriter = new Typewriter('#sdlogoTextTypewriter', {
        loop: true,
        delay: 35,
    })

    typewriter
        .typeString('cloud public')
        .pauseFor(400)
        .deleteChars(12)
        .typeString('on-premise')
        .pauseFor(400)
        .deleteChars(10)
        .typeString('hybride')
        .pauseFor(400)
        .deleteChars(7)
        .typeString('multi-cloud')
        .pauseFor(400)
        .start()


    $JC('.zone_sdlogo').click(() => {
        console.log('Click to scaledynamics.com')
        window.location.href = "https://scaledynamics.com/index-fr"
    })

    $JC('#boxHautGauche').click(() => {
        console.log('Click to big.bzh')
        window.location.href = "https://www.big.bzh"
    })
}

let firstUpdate = true
let bigStatus
function bigUpdate(status) {
    console.log('bigUpdate', status)
    bigStatus = status
    if (firstUpdate) {
        showStats()
        refreshView()
    }
    firstUpdate = false
}

function error(e) {
    console.log('error', e)
    if (timeoutRefreshView) clearTimeout(timeoutRefreshView)
    $JC('#running').hide()
    $JC('#photoerror').show()
}

$JC(document).ready(async function () {

    $JC('#cog').html('Big<br>' + version)
    try {
        await big.register({
            updateStatus: bigUpdate,
            startJob: startJob,
            oneJobDone: oneJobDone,
            waitJob: waitJob,
            error: error,
        })

        initWidget()

        if (deviceId === undefined) {
            let infos = {
                userAgent: navigator.userAgent,
                cpus: navigator.hardwareConcurrency
            }

            function getDeviceType() {
                const ua = infos.userAgent;
                if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                    return "tablet";
                }
                if (
                    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                        ua
                    )
                ) {
                    return "mobile";
                }
                return "desktop";
            }
            infos.deviceType = getDeviceType()

            console.log('device informations', infos)

            deviceId = await big.newDevice(infos)
            if (deviceId !== undefined) {
                Cookies.set(`big-device-id`, deviceId, {
                    domain: process.env.FE_COOKIE_DOMAIN,
                    expires: 365, // in days
                    path: '/',
                    sameSite: 'None',
                    secure: true
                })
            }
        } else {
            await big.deviceBack(deviceId)
        }
        showImage()
        await big.start()
    } catch (e) {
        error("Unable to init")
    }
});

