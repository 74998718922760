'use strict'
/*
 * Copyright 2021 ScaleDynamic SAS. All rights reserved.
 * Do not distribute
 * 
 *  BIG web frontend
 * 
 */
let debug = false
if (process.env.FE_DEBUG === "true") debug = true

let bigStatus
import ModuleBig from './.module/module-core'
let moduleBig
let frontEnd
let deviceId

// PROTOCOL client -> big
// 1) register
// 2) newDevice or deviceBack
// 3) start

// Handlers big -> handler
// update (update stats)
// error(error)
async function register(config) {
    console.log('REGISTER')
    if (config.error === undefined) throw "Missing error handler"
    if (config.updateStatus === undefined) throw "Missing updateStatus handler"
    if (config.startJob === undefined) throw "Missing startJob handler"
    if (config.waitJob === undefined) throw "Missing waitJob handler"
    if (config.oneJobDone === undefined) throw "Missing oneJobDone handler"

    frontEnd = config
    await ModuleBig.loadEngine()
    moduleBig = new ModuleBig({ env: process.env.MODULE_CORE_ENV })
}

async function newDevice(infos) {
    deviceId = await moduleBig.newDevice(infos)
    return deviceId
}

async function deviceBack(tdeviceId) {
    await moduleBig.deviceBack(tdeviceId)
    deviceId = tdeviceId
    return
}

let bigWorker
function workerStartNewCompute(job) {
    bigWorker = new Worker('big_worker.js')

    bigWorker.addEventListener('message', async function (e) {
        if (e.data.cmd === 'endOfJob') {
            if (debug === true) console.log('big - Received from worker', e.data.job)
            bigWorker.terminate()
            bigWorker = undefined
            await processEndOfJob(e.data.job)
            return
        }
        throw "Bad answer from worker :" + e
    }, false)
    bigWorker.postMessage({ cmd: 'startNewCompute', job: job });
}

let getJobTimeout
async function processEndOfJob(job) {
    if (debug === true) console.log('big - processEndOfJob', job)
    try {
        let ejob = {
            name: job.name,
            res: job.res,
        }
        await moduleBig.endOneJob(deviceId, ejob)
    } catch (e) {
        frontEnd.error(e)
    }
    frontEnd.oneJobDone()
    getJobTimeout = setTimeout(getOneJob, bigStatus.feJobRefresh * 1000)
}

async function getOneJob() {
    let job
    try {
        job = await moduleBig.getOneJob(deviceId)
        if (debug === true) console.log('big - getOneJob: ', job)
    } catch (e) {
        frontEnd.error(e)
        return
    }

    if (job === undefined) {
        frontEnd.waitJob()
        getJobTimeout = setTimeout(getOneJob, bigStatus.feJobRefresh * 1000)
        return
    }
    frontEnd.startJob(job.desc)
    getJobTimeout = undefined

    workerStartNewCompute(job)
}

let newVisit = true
let poolingTimeout
async function getStatus() {
    if (debug === true) console.log('big - getStatus')
    bigStatus = await moduleBig.getStatus(newVisit)
    newVisit = false
    if (bigStatus.running === undefined) {
        frontEnd.error("Internal Error - Bad Big status running")
        return false
    }
    if (bigStatus.feStateRefresh === undefined) {
        frontEnd.error("Internal Error - Bad Big status feStateRefresh")
        return false
    }
    if (bigStatus.feJobRefresh === undefined) {
        frontEnd.error("Internal Error - Bad Big status feJobRefresh")
        return false
    }
    if (bigStatus.stats === undefined) {
        frontEnd.error("Internal Error - Bad Big stats")
        return false
    }

    frontEnd.updateStatus(bigStatus.stats)
    poolingTimeout = setTimeout(getStatus, bigStatus.feStateRefresh * 1000)
    return true
}

async function start() {
    if (debug === true) console.log('big - start')
    const ok = await getStatus()
    if (ok === false) return
    await getOneJob()
}

async function stop() {
    if (poolingTimeout !== undefined) clearTimeout(poolingTimeout)
    if (getJobTimeout !== undefined) clearTimeout(getJobTimeout)
    if (bigWorker !== undefined) bigWorker.terminate()
}

module.exports.register = register
module.exports.newDevice = newDevice
module.exports.deviceBack = deviceBack
module.exports.start = start
module.exports.stop = stop